import { createSelector } from 'reselect';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type {
	ActivityFeedUiState,
	ActivityItem,
} from '@atlassian/jira-issue-view-common-types/src/activity-item-type';
import type { UiState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { uiSelector } from '../common/state/selectors/issue-selector';

const getUiActivityFeed = createSelector(
	uiSelector,
	(ui: UiState): ActivityFeedUiState => ui.activityFeed,
);

export const getSelectedActivityItem = createSelector(
	getUiActivityFeed,
	(activityFeed: ActivityFeedUiState): ActivityItem | null => activityFeed.selectedActivityItem,
);

export const getSelectedActivitySortOrder = createSelector(
	getUiActivityFeed,
	(activityFeed: ActivityFeedUiState): ActivitySortOrderType =>
		activityFeed.selectedActivitySortOrder,
);
