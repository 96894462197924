import { createSelector } from 'reselect';
import { isWithinInterval, subDays, startOfDay, endOfDay, parseISO } from 'date-fns';
import {
	type AnalyticsSource,
	BITBUCKET_ISSUE,
	MOBILE_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources';
import type { ApplicationKey } from '@atlassian/jira-common-constants/src/application-keys';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import type {
	ViewModeType,
	ContextState,
} from '@atlassian/jira-issue-view-common-types/src/context-type';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	serviceDeskConfiguration,
	simpleSoftwareBoardConfiguration,
	softwareConfiguration,
	standardRoadmapConfiguration,
} from '@atlassian/jira-issue-view-configurations';
import type { ApplicationEditions } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	type AccountId,
	type BaseUrl,
	type CloudId,
	type ActivationId,
	type IssueId,
	type IssueKey,
	type ProjectKey,
	toCloudId,
	toActivationId,
	toIssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	type ApplicationLicenseStates,
	LICENSE_STATE_ACTIVE,
} from '@atlassian/jira-shared-types/src/license-state.tsx';
import type {
	ApplicationPermissions,
	LicensedProducts,
	ProductCrossSellConfig,
	SiteAdminStatus,
} from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export const contextSelector = (state: State): ContextState => state.context;

export const accountIdloggedInUserSelector = createSelector(
	contextSelector,
	(context: ContextState): AccountId | null => context.atlassianAccountId,
);

export const orgIdSelector = createSelector(
	contextSelector,
	(context: ContextState): string | null => context?.orgId || null,
);

/** @deprecated baseUrl in cloud is an empty string. remove code that checks or uses it. */
export const baseUrlSelector = (_: unknown): BaseUrl => '';

/**
 * @deprecated - use useLoginRedirectUrl instead
 */
export const loginRedirectUrlSelector = createSelector(
	contextSelector,
	(context: ContextState): string | undefined => context.loginRedirectUrl,
);

/**
 * @deprecated - use useIssueKey instead
 */
export const issueKeySelector = createSelector(
	contextSelector,
	(context: ContextState): IssueKey => toIssueKey(context.issueKey),
);

/**
 * @deprecated - use useIssueId instead
 */
export const issueIdSelector = createSelector(
	contextSelector,
	(context: ContextState): IssueId | undefined => context.issueId,
);

export const projectKeySelector = createSelector(
	issueKeySelector,
	(issueKey: IssueKey): ProjectKey => extractProjectKey(issueKey),
);

/**
 *  @deprecated - use useAnalyticsSource instead.
 */
export const analyticsSourceSelector = createSelector(contextSelector, (context: ContextState) =>
	context.analyticsSource !== undefined ? context.analyticsSource : undefined,
);

export const cloudIdSelector = createSelector(
	contextSelector,
	(context: ContextState): CloudId => context.cloudId || toCloudId(''),
);

export const activationIdSelector = createSelector(
	contextSelector,
	(context: ContextState): ActivationId => context.activationId || toActivationId(''),
);

export const isAnonymousSelector = createSelector(
	contextSelector,
	(context: ContextState): boolean => context.isAnonymous,
);

export const localeSelector = createSelector(
	contextSelector,
	(context: ContextState): Locale => context.locale,
);

export const issueActionsSelector = createSelector(
	contextSelector,
	(context: ContextState): string[] => context.issueActions || [],
);

/**
 * @deprecated `applicationKey` is deprecated because it is unreliable and is causing bugs from time to time
 */
export const applicationKeySelector = createSelector(
	contextSelector,
	(context: ContextState): ApplicationKey | undefined => context.applicationKey,
);

export const isMobileSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	analyticsSourceSelector,
	(analyticsSource?: AnalyticsSource): boolean => analyticsSource === MOBILE_ISSUE,
);

/**
 * @deprecated `applicationKey` is deprecated because it is unreliable and is causing bugs from time to time
 * Replace usages with isSimplifiedProjectSelector()
 */
export const isAgilityProjectSelector = createSelector(
	applicationKeySelector,
	(applicationKey?: ApplicationKey): boolean =>
		applicationKey === simpleSoftwareBoardConfiguration().applicationKey ||
		applicationKey === standardRoadmapConfiguration().applicationKey,
);

/**
 * @deprecated because`applicationKey` is deprecated because it is unreliable and is causing bugs from time to time
 * Use projectTypeSelector() instead
 */
export const isSoftwareSelector = createSelector(
	applicationKeySelector,
	(applicationKey?: ApplicationKey): boolean =>
		applicationKey === softwareConfiguration().applicationKey,
);

export const isBitbucketSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	analyticsSourceSelector,
	(analyticsSource?: AnalyticsSource): boolean => analyticsSource === BITBUCKET_ISSUE,
);

/**
 * @deprecated because `applicationKey` is deprecated because it is unreliable and is causing bugs from time to time
 * Use projectTypeSelector() instead
 */
export const isServiceDeskSelector = createSelector(
	applicationKeySelector,
	(applicationKey?: ApplicationKey): boolean =>
		applicationKey === serviceDeskConfiguration().applicationKey,
);

export const isEcosystemEnabledSelector = createSelector(
	contextSelector,
	(context: ContextState): boolean => !!context.ecosystemEnabled,
);

/**
 * @deprecated use useRapidViewId instead
 */
export const rapidViewIdSelector = createSelector(
	contextSelector,
	(context: ContextState): number | undefined => context.rapidViewId,
);

/**
 * @deprecated use useEstimationStatistic instead
 */
export const estimationStatisticSelector = createSelector(
	contextSelector,
	(context: ContextState): string | null => context.estimationStatistic || null,
);

/**
 * @deprecated use useBoardType instead
 */
export const boardTypeSelector = createSelector(
	contextSelector,
	(context: ContextState): string | null | undefined => context.boardType,
);

/**
 * @deprecated use useBoardId instead
 */
export const boardIdSelector = createSelector(
	contextSelector,
	(context: ContextState): number | null | undefined => context.boardId,
);

export const siteAdminStatusSelector = createSelector(
	contextSelector,
	(context: ContextState): SiteAdminStatus => context.siteAdminStatus,
);

export const productCrossSellConfigSelector = createSelector(
	contextSelector,
	(context: ContextState): ProductCrossSellConfig => context.productCrossSellConfig,
);

export const licensedProductsSelector = createSelector(
	contextSelector,
	(context: ContextState): LicensedProducts => context.licensedProducts,
);

export const appPermissionsSelector = createSelector(
	contextSelector,
	(context: ContextState): ApplicationPermissions => context.appPermissions,
);

/**
 * @deprecated: - use useIsModal instead
 */
export const isModalSelector = createSelector(
	contextSelector,
	(context: ContextState): boolean => context.isModal,
);

export const shouldRenderHeaderSelector = createSelector(
	isModalSelector,
	isMobileSelector,
	(isModal, isMobile): boolean => !isModal && !isMobile,
);

/**
 * @deprecated: - use useViewMode instead
 */
export const viewModeSelector = createSelector(
	contextSelector,
	(context: ContextState): ViewModeType | null => context.viewMode,
);

export const appEditionsSelector = createSelector(
	contextSelector,
	(context: ContextState): ApplicationEditions => context.appEditions,
);

export const licenseStatesSelector = createSelector(
	contextSelector,
	(context: ContextState) => context.licenseStates,
);

export const isServiceDeskLicenseActiveSelector = createSelector(
	licenseStatesSelector,
	(licenseStates: ApplicationLicenseStates) =>
		licenseStates && licenseStates.serviceDesk === LICENSE_STATE_ACTIVE,
);

export const tenantHasActiveServiceDeskLicenseSelector = createSelector(
	licensedProductsSelector,
	isServiceDeskLicenseActiveSelector,
	(licensedProducts, isServiceDeskLicenseActive) =>
		licensedProducts &&
		licensedProducts[LICENSED_PRODUCTS.JIRA_SERVICEDESK] &&
		isServiceDeskLicenseActive,
);

export const hasShownIntentToXflowToConfluenceTraitSelector = createSelector(
	contextSelector,
	(context: ContextState): boolean => context.hasShownIntentToXflowToConfluence,
);

export const confluenceActivationDateTraitSelector = createSelector(
	contextSelector,
	(context: ContextState): string | undefined => context.confluenceActivationDate,
);

export const hasActivatedConfluenceWithinFourteenDaysSelector = createSelector(
	confluenceActivationDateTraitSelector,
	(confluenceActivationDate) => {
		try {
			if (!confluenceActivationDate) return false;
			const today = endOfDay(new Date());
			const fourteenDaysAgo = startOfDay(subDays(today, 14));
			const dateToCheck = parseISO(confluenceActivationDate);

			return isWithinInterval(dateToCheck, {
				start: fourteenDaysAgo,
				end: today,
			});
		} catch (error) {
			return false;
		}
	},
);
