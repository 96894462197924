import { createSelector } from 'reselect';
import find from 'lodash/find';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { RICH_CONTENT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type {
	Field,
	FieldsState,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import {
	CHILDREN_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	ENVIRONMENT,
	DESCRIPTION,
	specialFields,
	FIX_VERSIONS,
	AFFECTS_VERSIONS,
} from '@atlassian/jira-issue-view-configurations';
import { TEXT_AREA_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { IssueLinksKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { isServiceDeskSelector } from './context-selector';
import getEditingValue, { getEditingAdfValue } from './get-editing-value';
import getPendingValue from './get-pending-value';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const STABLE_EMPTY_ARRAY: any[] = [];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const STABLE_EMPTY_OBJECT: Record<string, any> = {};

export const fieldsSelector = (state: State): FieldsState => state.fields || STABLE_EMPTY_OBJECT;

const getFieldByCustomType = (customType: string, fields: FieldsState): Field | undefined =>
	find(fields, (field: Field) => (field.schema && field.schema.custom) === customType);

export const getFieldHandlingSpecialFields = (
	fieldId: string,
	fields: FieldsState,
): Field | undefined => {
	let field = fields[fieldId];
	const customSystemFields = specialFields();
	if (!field && Object.keys(customSystemFields).includes(fieldId)) {
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'SpecialFieldTypes'.
		const customType = customSystemFields[fieldId];
		// @ts-expect-error - TS2322 - Type 'Field | undefined' is not assignable to type 'Field'.
		field = getFieldByCustomType(customType, fields);
	}
	return field;
};

export const doesFieldExistsSelector = createSelector(
	fieldsSelector,
	(state: State, { fieldId }: { fieldId: string }) => fieldId,
	(fields, fieldId) => !!fields[fieldId],
);

export const doesFieldHaveValue = (fieldsState: FieldsState, fieldName: string): boolean =>
	Array.isArray(fieldsState[fieldName]?.value)
		? fieldsState[fieldName].value.length > 0
		: fieldsState[fieldName]?.value != null;

export const fieldByCustomTypeSelector = (
	customType: string,
): ((arg1: State) => Field | undefined) =>
	createSelector(fieldsSelector, (fields: FieldsState): Field | undefined =>
		getFieldByCustomType(customType, fields),
	);

export const customFieldIdSelector = (customFieldType: string) =>
	createSelector(
		fieldByCustomTypeSelector(customFieldType),
		(customField?: Field): number | null => {
			const idCandidates = customField ? customField.key.match(/customfield_(\d+)$/i) : null;

			return idCandidates ? +idCandidates[1] : null;
		},
	);

export const fieldSelector = (fieldId: string): ((arg1: State) => Field | undefined) =>
	createSelector(fieldsSelector, (fields: FieldsState): Field | undefined =>
		getFieldHandlingSpecialFields(fieldId, fields),
	);

export const fieldSelector2 = createSelector(
	fieldsSelector,
	(state: State, { fieldId }: { fieldId: string }) => fieldId,
	(fields, fieldId) => getFieldHandlingSpecialFields(fieldId, fields),
);

export const hasIssueLinksSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): boolean => !!Object.keys(fields).includes(IssueLinksKey),
);

export const hasSubtasksSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): boolean => !!Object.keys(fields).includes('subtasks'),
);

export const hasSubtasksValuesSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): boolean => doesFieldHaveValue(fields, 'subtasks'),
);

export const hasPortfolioChildIssuesSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): boolean => !!Object.keys(fields).includes(PORTFOLIO_CHILD_ISSUES),
);

export const hasPortfolioChildIssueValuesSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): boolean => doesFieldHaveValue(fields, PORTFOLIO_CHILD_ISSUES),
);

export const hasChildrenIssuesSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): boolean => !!Object.keys(fields).includes(CHILDREN_ISSUES),
);

export const hasChildrenIssueValuesSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): boolean => doesFieldHaveValue(fields, CHILDREN_ISSUES),
);

export const childIssuesLimitUrlSelector = createSelector(fieldsSelector, (fields) => {
	const getChildIssueUrlSelector = (fieldId: string) => fields?.[fieldId]?.issueLimitUrl || '';

	return getChildIssueUrlSelector;
});

export const fieldsDataSelector = createSelector(fieldsSelector, (fields) =>
	Object.keys(fields)
		.map((fieldKey) => getFieldHandlingSpecialFields(fieldKey, fields))
		.filter(Boolean),
);

export const availableFieldIdsSelector = createSelector(
	fieldsSelector,
	(fields: FieldsState): string[] => Object.keys(fields),
);

export const isFieldEditable = (field?: Field): boolean => (field ? field.editable : false);

export const fieldSchemaConfigurationSelector = (
	fieldId: string,
): ((arg1: State) =>
	| {
			[key: string]: string;
	  }
	| null
	| undefined) =>
	createSelector(
		fieldSelector(fieldId),
		(
			field?: Field,
		):
			| {
					[key: string]: string;
			  }
			| null
			| undefined =>
			field &&
			field.schema &&
			field.schema.configuration &&
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			field.schema.configuration.reduce<Record<string, any>>(
				(config, { key, value }) => ({
					// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
					...config,
					[key]: value,
				}),
				{},
			),
	);

export const isFieldEditableSelector = (fieldId: string): ((arg1: State) => boolean) =>
	createSelector(fieldSelector(fieldId), (field?: Field): boolean => isFieldEditable(field));

export const fieldNameSelector = (fieldId: string): ((arg1: State) => string) =>
	createSelector(fieldSelector(fieldId), (field?: Field): string => {
		if (field && field.title) {
			return field.title;
		}
		log.safeWarnWithoutCustomerData(
			'issue.field.selector',
			`${fieldId} does not have a field title`,
		);
		return '';
	});

export const fieldNameSelector2 = createSelector(
	fieldSelector2,
	(state: State, { fieldId }: { fieldId: string }) => fieldId,
	(field, fieldId): string => {
		if (field?.title) {
			return field.title;
		}
		log.safeWarnWithoutCustomerData(
			'issue.field.selector',
			`${fieldId} does not have a field title`,
		);
		return '';
	},
);

export const fieldMetaKeySelector = (fieldId: string): ((arg1: State) => string | undefined) =>
	createSelector(fieldSelector(fieldId), (field?: Field): string | undefined => field && field.key);

export const fieldARISelector = (fieldId: string): ((arg1: State) => string | undefined) =>
	createSelector(fieldSelector(fieldId), (field?: Field): string | undefined =>
		field ? field.ari : undefined,
	);

export const fieldAutoCompleteUrlSelector = (fieldId: string): ((arg1: State) => string | null) =>
	// @ts-expect-error - TS2322 - Type 'OutputSelector<Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, string | ... 1 mo...' is not assignable to type '(arg1: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => string | null'.
	createSelector(
		fieldSelector(fieldId),
		(field?: Field): string | null | undefined => field && field.autoCompleteUrl,
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fieldAllowedValuesSelector = (fieldId: string): ((arg1: State) => any[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createSelector(fieldSelector(fieldId), (field?: Field): any[] =>
		field ? field.allowedValues : STABLE_EMPTY_ARRAY,
	);

export const isFieldRequiredSelector = (fieldId: string): ((arg1: State) => boolean) =>
	createSelector(fieldSelector(fieldId), (field?: Field): boolean =>
		field ? field.required : false,
	);

export const fieldTypeSelector = (fieldId: string): ((arg1: State) => string | null) =>
	// @ts-expect-error - TS2322 - Type 'OutputSelector<Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, string | ... 1 mo...' is not assignable to type '(arg1: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => string | null'.
	createSelector(
		fieldSelector(fieldId),
		(field?: Field): string | null | undefined => field && getFieldType(field),
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fieldPersistedValueSelector = (fieldId: string): ((arg1: State) => any) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createSelector(fieldSelector(fieldId), (field?: Field): any => field && field.value);

export const fieldPersistedValueSelector2 = createSelector(fieldSelector2, (field) => field?.value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fieldPendingValueSelector = (fieldId: string): ((arg1: State) => any) =>
	createSelector(fieldSelector(fieldId), (field?: Field) => field && getPendingValue(field));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fieldEditingValueSelector = (fieldId: string): ((arg1: State) => any) =>
	createSelector(fieldSelector(fieldId), (field?: Field) => field && getEditingValue(field));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fieldEditingAdfValueSelector = (fieldId: string): ((arg1: State) => any) =>
	createSelector(
		fieldSelector(fieldId),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(field?: Field): any => field && getEditingAdfValue(field),
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fieldHtmlValueSelector = (fieldId: string): ((arg1: State) => any) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createSelector(fieldSelector(fieldId), (field?: Field): any => field && field.renderedValue);

export const fieldHtmlValueSelector2 = createSelector(
	fieldSelector2,
	(field) => field?.renderedValue,
);

export const isEditingField = (field?: Field): boolean =>
	field ? field.editingValue !== undefined : false;

export const isFieldEditingSelector = (fieldId: string): ((arg1: State) => boolean) =>
	createSelector(fieldSelector(fieldId), (field?: Field): boolean => isEditingField(field));

export const fieldEditSessionIdSelector = (fieldId: string): ((arg1: State) => string) =>
	createSelector(
		fieldSelector(fieldId),
		(field?: Field): string => (field ? field.fieldEditSessionId : null) || '',
	);

export const isFieldWaitingSelector = (fieldId: string): ((arg1: State) => boolean) =>
	createSelector(fieldSelector(fieldId), (field?: Field): boolean =>
		field ? field.pendingValue !== undefined : false,
	);

export const fieldInvalidMessageSelector = (
	fieldId: string,
	fallbackInvalidMessage?: string,
): ((arg1: State) => string | null) =>
	// @ts-expect-error - TS2322 - Type 'OutputSelector<Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, string | ... 1 mo...' is not assignable to type '(arg1: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => string | null'.
	createSelector(
		fieldSelector(fieldId),
		(field?: Field): string | null | undefined =>
			field && (field.isInvalid ? field.invalidMessage || fallbackInvalidMessage : null),
	);

export const fieldInvalidSelector = (fieldId: string): ((arg1: State) => boolean) =>
	createSelector(fieldSelector(fieldId), (field?: Field): boolean =>
		Boolean(field && field.isInvalid),
	);

export const fieldRendererSelector = (fieldId: string): ((arg1: State) => string | null) =>
	// @ts-expect-error - TS2322 - Type 'OutputSelector<Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, string | ... 1 mo...' is not assignable to type '(arg1: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => string | null'.
	createSelector(
		fieldSelector(fieldId),
		(field?: Field): string | null | undefined => field && field.schema && field.schema.renderer,
	);

export const isMultilineTextField = (fieldType: string | null): boolean =>
	fieldType === TEXT_AREA_CF_TYPE || fieldType === ENVIRONMENT || fieldType === DESCRIPTION;

export const isRichTextFieldSelector = (fieldId: string): ((arg1: State) => boolean) =>
	createSelector(
		isServiceDeskSelector,
		fieldTypeSelector(fieldId),
		fieldRendererSelector(fieldId),
		(isServiceDesk: boolean, fieldType: string | null, fieldRenderer: string | null): boolean => {
			const isDescription = fieldId === DESCRIPTION;
			const isMultilineRichTextField =
				isMultilineTextField(fieldType) && fieldRenderer === RICH_CONTENT;

			return isDescription || isMultilineRichTextField;
		},
	);

export const richTextFieldIdsSelector = createSelector(
	fieldsSelector,
	(state) => state,
	(fields: FieldsState, state: State): string[] =>
		Object.keys(fields).filter((fieldId) => isRichTextFieldSelector(fieldId)(state)),
);

export const isRichTextFieldEditingSelector = createSelector(
	richTextFieldIdsSelector,
	(state) => state,
	(richTextFieldIds: string[], state: State): boolean =>
		richTextFieldIds.some((fieldId) => isFieldEditingSelector(fieldId)(state)),
);

export const isMultilineFieldSelector = (fieldId: string): ((arg1: State) => boolean) =>
	createSelector(fieldTypeSelector(fieldId), (fieldType: string | null): boolean =>
		isMultilineTextField(fieldType),
	);

export const multilineFieldIdsSelector = createSelector(
	fieldsSelector,
	(state) => state,
	(fields: FieldsState, state: State): string[] =>
		Object.keys(fields).filter((fieldId) => isMultilineFieldSelector(fieldId)(state)),
);

export const getEditingMultilineFieldIdsSelector = createSelector(
	multilineFieldIdsSelector,
	(state) => state,
	(multilineFieldsIds: string[], state: State): string[] =>
		multilineFieldsIds.filter((fieldId) => isFieldEditingSelector(fieldId)(state)),
);

export const getVersionsFieldIds = createSelector(
	fieldSelector(FIX_VERSIONS),
	fieldSelector(AFFECTS_VERSIONS),
	(fixVersions, affectsVersions) => {
		const ids: Array<string> = [];
		if (fixVersions) {
			ids.push(fixVersions.key);
		}
		if (affectsVersions) {
			ids.push(affectsVersions.key);
		}
		return ids;
	},
);
