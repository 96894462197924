import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { CHILDREN_ISSUES } from '@atlassian/jira-platform-field-config';
import {
	fieldSelector,
	childIssuesLimitUrlSelector,
} from '../../../common/state/selectors/field-selector';

const childrenIssueSelector: (arg1: State) => Field | undefined = fieldSelector(CHILDREN_ISSUES);

export const hasChildrenIssuesSelector = createSelector(
	childrenIssueSelector,
	childIssuesLimitUrlSelector,
	(childrenIssues, getChildIssuesLimitUrl) =>
		childrenIssues
			? !isEmpty(childrenIssues.value) || Boolean(getChildIssuesLimitUrl(CHILDREN_ISSUES))
			: false,
);
