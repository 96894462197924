import { createSelector, type OutputSelector } from 'reselect';
import type {
	IssueType as IssueTypeForHierarchyLevel,
	IssueTypesForHierarchyLevel,
} from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import {
	type HierarchyLevel,
	isBaseHierarchyLevel,
	isSubtaskHierarchyLevel,
	toHierarchyLevel,
} from '@atlassian/jira-issue-type-hierarchies';
import type { Permissions } from '@atlassian/jira-issue-view-common-constants/src/permissions.tsx';
import type {
	EntitiesState,
	IssueType,
	State,
} from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	HIERARCHY_LEVEL_ABOVE,
	HIERARCHY_LEVEL_BELOW,
	HIERARCHY_LEVEL_SAME_LEVEL,
} from '@atlassian/jira-platform-field-config';
import { fieldPersistedValueSelector } from '../../../common/state/selectors/field-selector';
import {
	entitiesSelector,
	isSimplifiedProjectSelector,
} from '../../../common/state/selectors/issue-selector';
import { permissionsSelector } from '../../../common/state/selectors/permissions-selector';
import { canEditIssuesSelector } from '../../../selectors/issue-actions-permissions-selector';

// @ts-expect-error - TS2322 - Type '(arg1: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => any' is not assignable to type 'OutputSelector<Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, undefined, IssueT...'.
const childLevelValueSelector: OutputSelector<
	State,
	undefined,
	IssueTypesForHierarchyLevel | null
> = fieldPersistedValueSelector(HIERARCHY_LEVEL_BELOW) || null;

const isChildLevelSubtaskLevelSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	childLevelValueSelector,
	(childLevelFieldValue: IssueTypesForHierarchyLevel | null): boolean => {
		if (!childLevelFieldValue) {
			return false;
		}
		return isSubtaskHierarchyLevel(childLevelFieldValue.level);
	},
);

export const supportedChildIssueTypesSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	childLevelValueSelector,
	(childLevelValue: IssueTypesForHierarchyLevel | null): IssueTypeForHierarchyLevel[] =>
		(childLevelValue && childLevelValue.issueTypes) || [],
);

export const supportedChildIssueTypesEntitiesSelector = createSelector(
	entitiesSelector,
	supportedChildIssueTypesSelector,
	(entities: EntitiesState, supportedIssueTypes: IssueTypeForHierarchyLevel[]): IssueType[] => {
		const allIssueTypes = entities.issueTypes;
		const supportedIssueTypeIds = supportedIssueTypes.map((issueType) => issueType.id);
		return allIssueTypes.filter(
			// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
			(issueType) => supportedIssueTypeIds.indexOf(issueType.id) !== -1,
		);
	},
);

// Supports child issue (except CMP subtask) creation - CMP subtasks are a special case and should be
// used instead if the current issue is at the base level (e.g story/task/bug) and therefore child issues
// are at the subtask level. Use canCreateSubtaskSelector instead if checking for ability to create children in this case
// Internal implementation of ffed method exported only for testing
export const supportsChildCreationSelector = createSelector(
	permissionsSelector,
	supportedChildIssueTypesSelector,
	isChildLevelSubtaskLevelSelector,
	isSimplifiedProjectSelector,
	(
		permissions: Permissions,
		supportedChildIssueTypes: IssueTypeForHierarchyLevel[],
		isChildLevelSubtask: boolean,
		isSimplifiedProject: boolean,
	): boolean =>
		permissions.canCreateChildren &&
		!!supportedChildIssueTypes.length &&
		(isSimplifiedProject ? true : !isChildLevelSubtask),
);

// @ts-expect-error - TS2322 - Type '(arg1: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => any' is not assignable to type 'OutputSelector<Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, undefined, IssueT...'.
const parentLevelValueSelector: OutputSelector<
	State,
	undefined,
	IssueTypesForHierarchyLevel | null
> = fieldPersistedValueSelector(HIERARCHY_LEVEL_ABOVE) || null;

export const parentLevelNameSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	parentLevelValueSelector,
	(parentLevelValue: IssueTypesForHierarchyLevel | null): string | null =>
		parentLevelValue && (parentLevelValue.name || null),
);

export const parentLevelIssueTypeIdsSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	parentLevelValueSelector,
	(parentLevelValue: IssueTypesForHierarchyLevel | null): string[] =>
		(parentLevelValue && parentLevelValue.issueTypeIds.map(String)) || [],
);

export const parentLevelIssueTypeNamesSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	parentLevelValueSelector,
	(parentLevelValue: IssueTypesForHierarchyLevel | null): string[] =>
		(parentLevelValue &&
			parentLevelValue.issueTypes.map((type) => type.name).filter((name) => name)) ||
		[],
);

export const hasParentLevelIssueTypeIdsSelector = createSelector(
	parentLevelIssueTypeIdsSelector,
	(parentLevelValue: string[]): boolean => parentLevelValue.length > 0,
);

// @ts-expect-error - TS2322 - Type '(arg1: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => any' is not assignable to type 'OutputSelector<Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, undefined, IssueT...'.
const sameLevelValueSelector: OutputSelector<State, undefined, IssueTypesForHierarchyLevel | null> =
	fieldPersistedValueSelector(HIERARCHY_LEVEL_SAME_LEVEL) || null;

export const isSameLevelSubtaskLevelSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	sameLevelValueSelector,
	(sameLevelFieldValue: IssueTypesForHierarchyLevel | null): boolean => {
		if (!sameLevelFieldValue) {
			return false;
		}
		return isSubtaskHierarchyLevel(sameLevelFieldValue.level);
	},
);

export const sameLevelIssueTypesIdsSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	sameLevelValueSelector,
	(sameLevelFieldValue: IssueTypesForHierarchyLevel | null): string[] =>
		sameLevelFieldValue ? sameLevelFieldValue.issueTypeIds.map(String) : [],
);

/**
 * Returns global hierarchy level. Can be either:
 *  -1 for subtask level
 *  0 for base level
 *  1 for epic level
 *  N for other levels: advanced roadmaps are probably going to expand this scale
 *
 * Reads the new global issue type hierarchy. Only use this one if collapse-nextgen-issuetype-hierarchy-fe-reads is enabled
 */
export const hierarchyLevelSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	sameLevelValueSelector,
	(sameLevelFieldValue: IssueTypesForHierarchyLevel | null): HierarchyLevel | null =>
		sameLevelFieldValue ? toHierarchyLevel(sameLevelFieldValue.level) : null,
);

/**
 * Reads the new global issue type hierarchy. Only use this one if collapse-nextgen-issuetype-hierarchy-fe-reads is enabled
 */
export const isIssueBaseLevel = createSelector(
	hierarchyLevelSelector,
	(level: HierarchyLevel | null) => {
		if (level == null) {
			// Some product types (JSM, Core, ...) do not support hierarchies at all and will not return this field.
			// For these, all issues are considered to be "Base" level.
			return true;
		}
		return isBaseHierarchyLevel(level);
	},
);

export const canLinkToParentSelector = createSelector(
	parentLevelNameSelector,
	canEditIssuesSelector,
	hasParentLevelIssueTypeIdsSelector,
	(
		parentLevelName: string | null,
		shouldShowAddParent: boolean,
		hasParentIssueTypeId: boolean,
	): boolean => Boolean(parentLevelName) && shouldShowAddParent && hasParentIssueTypeId,
);
