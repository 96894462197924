import find from 'lodash/find';
import { doesExtensionMeetConditions } from '@atlassian/jira-forge-ui-utils/src/utils/conditions';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	type ContextPanelItem,
	FORGE_CONTEXT_TYPE,
	type GlanceItem,
	layoutContainerItemTypes,
	type LayoutContainerNode,
	type LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants';
import type { ContextPanel } from '@atlassian/jira-issue-shared-types/src/common/types/context-panel-type.tsx';
import { CONNECT_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import { PIN_FIELD } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants';
import { isForgeField } from '@atlassian/jira-issue-view-common-utils/src/layout/index.tsx';
import { specialFields as CUSTOM_SYSTEM_FIELDS } from '@atlassian/jira-issue-view-configurations';
import type {
	EcosystemContextPanels,
	EcosystemGlances,
} from '@atlassian/jira-issue-view-ecosystem-service/src/services/types';
import {
	useForgeContextPanels,
	useForgeGlances,
} from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-forge-service/src/services/types';
import {
	getFieldType,
	getPanels,
	isEmpty,
} from '@atlassian/jira-issue-view-layout-templates-utils';
import {
	ASSIGNEE_TYPE,
	ECOSYSTEM_CONTEXT_TYPE,
	ECOSYSTEM_GLANCE_TYPE,
	FORGE_GLANCE_TYPE,
	REQUEST_LANGUAGE_CF_TYPE,
	REQUEST_TYPE_CF_TYPE,
	SLA_PANEL_TYPE,
	TIME_TRACKING_TYPE,
} from '@atlassian/jira-platform-field-config';
import {
	useProjectConfiguration,
	useProjectId,
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';

export const shouldHideEmptyTimeTrackingField = (
	canLogTime: boolean,
	isNativeJiraTimeTrackingEnabled: boolean,
) =>
	// Here we assume that if the time tracking field is an available field
	// but isNativeJiraTimeTrackingEnabled is false, a 3rd party time tracking app is installed.
	isNativeJiraTimeTrackingEnabled ? !canLogTime : false;

const getFieldByCustomType = (customType: string, fieldsConfiguration: IssueConfiguration) =>
	find(fieldsConfiguration, (field) => (field.schema && field.schema.custom) === customType);

const getFieldConfiguration = (fieldId: string, fields: IssueConfiguration) => {
	const field = fields[fieldId];
	const customSystemFields = CUSTOM_SYSTEM_FIELDS();

	const condition = !field && Object.prototype.hasOwnProperty.call(customSystemFields, fieldId);

	if (condition) {
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'SpecialFieldTypes'.
		const customType = customSystemFields[fieldId];
		return getFieldByCustomType(customType, fields);
	}
	return field;
};

export const shouldHideNullUneditableField = (
	itemId: string,
	issueFieldsConfig: IssueConfiguration,
	value: string | undefined,
	canLogTime: boolean,
	isNativeJiraTimeTrackingEnabled: boolean,
) => {
	const fieldConfig = getFieldConfiguration(itemId, issueFieldsConfig);
	const fieldType = getFieldType(fieldConfig);

	if (getPanels().includes(itemId)) {
		return false;
	}

	if (!fieldConfig || fieldType === undefined) {
		return true;
	}

	if (isEmpty(value) && !fieldConfig.isEditable) {
		if (
			itemId === ASSIGNEE_TYPE ||
			fieldType === REQUEST_TYPE_CF_TYPE ||
			fieldType === REQUEST_LANGUAGE_CF_TYPE
		) {
			return false;
		}

		// ARKEN-40: Forge custom fields with readonly and an empty value must still be shown as primary or secondary items
		if (isForgeField(fieldConfig)) {
			return false;
		}

		if (itemId === TIME_TRACKING_TYPE)
			return shouldHideEmptyTimeTrackingField(canLogTime, isNativeJiraTimeTrackingEnabled);

		return true;
	}
	return false;
};

export const ecosystemGlancesToLayoutItem = (ecosystemGlances: EcosystemGlances): GlanceItem[] => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result = Object.keys(ecosystemGlances).reduce<Array<any>>((acc, next) => {
		const ecosystem = ecosystemGlances[next];
		const modules = Object.keys(ecosystem).map((moduleKey) => ecosystem[moduleKey]);
		// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
		return [...acc, ...modules] as const;
	}, []);

	return result.map((connectGlance) => ({
		id: `${ECOSYSTEM_GLANCE_TYPE}__${connectGlance.appKey}__${connectGlance.moduleKey}`,
		type: ECOSYSTEM_GLANCE_TYPE,
		payload: {
			...connectGlance,
			glanceType: CONNECT_ENTITY_TYPE,
		},
	}));
};

export const ecosystemContextsToLayoutItem = (
	ecosystemContextPanels: EcosystemContextPanels,
): ContextPanelItem[] => {
	const contextPanels: ContextPanel[] = [];

	Object.values(ecosystemContextPanels).forEach((contextPanelObject) => {
		contextPanels.push(Object.values(contextPanelObject)[0]);
	});

	return contextPanels.map((connectContextPanel: ContextPanel) => ({
		id: `${ECOSYSTEM_CONTEXT_TYPE}__${connectContextPanel.appKey}__${connectContextPanel.moduleKey}`,
		type: ECOSYSTEM_CONTEXT_TYPE,
		payload: {
			...connectContextPanel,
			contextPanelType: CONNECT_ENTITY_TYPE,
		},
	}));
};

const ISSUE_TYPE = 'issuetype';
export const useStaticContext = () => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const projectKey = useProjectKey(issueKey);
	const [permissions] = useProjectPermissions(projectKey);
	const projectType = useProjectType(projectKey);
	const projectId = useProjectId(projectKey);

	const [issueType] = useFieldValue({
		issueKey,
		fieldKey: ISSUE_TYPE,
	});

	return {
		issueId: issueId || null,
		issueKey,
		issueType: issueType && issueType.name,
		projectId,
		projectKey,
		projectType: projectType || null,

		// Prop normalisation copied from original redux selector - Props names are exposed externally.
		canAddComments: permissions.canAddComments,
		canAdministerJira: permissions.canAdministerJira,
		canAdministerProjects: permissions.canAdministerProject,
		canAssignIssues: permissions.canAssignIssues,
		canBeAssignedToIssues: permissions.canBeAssignedToIssues,
		canCloneIssues: permissions.canCloneIssue,
		canCreateAttachments: permissions.canCreateAttachments,
		canCreateChildren: permissions.canCreateChildren,
		canCreateSubtasks: permissions.canCreateSubtask,
		canDeleteAllAttachments: permissions.canDeleteAllAttachments,
		canDeleteAllComments: permissions.canDeleteAllComments,
		canDeleteAllWorklogs: permissions.canDeleteAllWorklogs,
		canDeleteIssues: permissions.canDeleteIssue,
		canDeleteOwnAttachments: permissions.canDeleteOwnAttachments,
		canDeleteOwnComments: permissions.canDeleteOwnComments,
		canDeleteOwnWorklogs: permissions.canDeleteOwnWorklogs,
		canEditAllComments: permissions.canEditAllComments,
		canEditAllWorklogs: permissions.canEditAllWorklogs,
		canEditIssues: permissions.canEditIssues,
		canEditOwnComments: permissions.canEditOwnComments,
		canEditOwnWorklogs: permissions.canEditOwnWorklogs,
		canLinkIssues: permissions.canLinkIssues,
		canLogWork: permissions.canLogWork,
		canManageWatchers: permissions.canManageWatchers,
		canModifyReporters: permissions.canModifyReporter,
		canMoveIssues: permissions.canMoveIssue,
		canScheduleIssues: permissions.canScheduleIssues,
		canUseServiceManagementAgentFeatures: permissions.canUseServiceDeskAgentFeatures,
		canBrowseUsers: permissions.canBrowseUsers,
		canViewDevTools: permissions.canViewDevTools,
		canViewWatchers: permissions.canViewWatchers,
	};
};

export const useForgeGlancesAsLayoutItems = () => {
	const [forgeGlances] = useForgeGlances();
	const staticContext = useStaticContext();
	return forgeGlances
		.filter((extension) => doesExtensionMeetConditions(extension, staticContext))
		.map<GlanceItem>((forgeGlance) => {
			const {
				id,
				properties: { title, label, status, icon },
			} = forgeGlance;
			return {
				id,
				type: FORGE_GLANCE_TYPE,
				payload: {
					label,
					title,
					status,
					icon,
					extension: forgeGlance,
					glanceType: FORGE_ENTITY_TYPE,
					moduleKey: id,
					appKey: id,
				},
			};
		});
};

// Can also be obtained with redux using forgeContextPanelsSelector
export const useForgeContextPanelsAsLayoutItems = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const { ecosystemEnabled } = useProjectConfiguration(projectKey);
	const [forgeContextPanels] = useForgeContextPanels();
	const staticContext = useStaticContext();

	if (!ecosystemEnabled) return [];

	return forgeContextPanels
		.filter((extension) => doesExtensionMeetConditions(extension, staticContext))
		.map<ContextPanelItem>((contextPanels) => {
			const {
				id,
				properties: { title, label, status, icon: url },
			} = contextPanels;
			return {
				id,
				type: FORGE_CONTEXT_TYPE,
				payload: {
					type: FORGE_CONTEXT_TYPE,
					label,
					name: title,
					status: status || null,
					icon: url ? { url } : null,
					extension: contextPanels,
					contextPanelType: FORGE_ENTITY_TYPE,
					moduleKey: id,
					appKey: id,
					options: null,
				},
			};
		});
};

export const addChangeboardingRefToFirstItem = (items: LayoutContainerTemplateItem[]) => {
	let wrapperAdded = false;
	const allowedTypes = [layoutContainerItemTypes.field, layoutContainerItemTypes.panel];
	const disallowedIds = [ASSIGNEE_TYPE, SLA_PANEL_TYPE];

	return items.map((item) => {
		const typename = item.type !== undefined ? item.type : '';
		// @ts-expect-error - TS2345 - Argument of type 'any' is not assignable to parameter of type 'never'.
		if (!wrapperAdded && !disallowedIds.includes(item.id) && allowedTypes.includes(typename)) {
			wrapperAdded = true;
			// if it was added to csat field and csat field to be removed later in servicedesk template, this effectively won't work
			return {
				...item,
				globalRef: PIN_FIELD,
			};
		}
		return item;
	});
};

export const getPinnedFieldItems = (dedupedItems: LayoutContainerNode[], pinnedFields: string[]) =>
	pinnedFields.reduce((result, pinnedId) => {
		const item = dedupedItems.find(
			(dedupedItem) =>
				(dedupedItem.type === layoutContainerItemTypes.field &&
					dedupedItem.fieldItemId === pinnedId) ||
				(dedupedItem.type === layoutContainerItemTypes.panel &&
					dedupedItem.panelItemId === pinnedId),
		);
		if (item !== undefined) {
			result.push(item);
		}
		return result;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, [] as LayoutContainerNode[]);

export const generateContextPanelId = ({
	appKey,
	moduleKey,
}: {
	appKey: string;
	moduleKey: string;
}) => `${ECOSYSTEM_CONTEXT_TYPE}__${appKey}__${moduleKey}`;
